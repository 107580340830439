import { createContext, useContext, useState, type FunctionComponent, type ReactNode } from 'react';
import { useClientEnvContext } from './clientEnvProvider';

const WORD_PRESS_BASE_URL = 'https://cms.buddica.direct';

type ContextType = {
  newsJson?: Record<string, string>[];
  specialJson?: Record<string, string>[];
  magazineJson?: Record<string, string>[];
  fetchnewsJson: () => void;
  fetchSpecialJson: () => void;
  fetchMagazineJson: () => void;
};

export const WordPressContext = createContext<ContextType>({
  fetchnewsJson: () => {},
  fetchSpecialJson: () => {},
  fetchMagazineJson: () => {}
});
export const useWordPressContext = (): ContextType => useContext(WordPressContext);

type Props = {
  children: ReactNode;
};

export const WordPressProvider: FunctionComponent<Props> = ({ children }) => {
  const { environment } = useClientEnvContext();
  const [newsJson, setnewsJson] = useState<Record<string, string>[]>();
  const [specialJson, setSpecialJson] = useState<Record<string, string>[]>();
  const [magazineJson, setMagazineJson] = useState<Record<string, string>[]>();

  const fetchnewsJson = async () => {
    if (newsJson) return;

    const url = `${WORD_PRESS_BASE_URL}/json-news`;
    try {
      const response = await fetch(url);
      const contents = (await response.json()) as Record<string, string>[];
      setnewsJson(contents);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchSpecialJson = async () => {
    if (specialJson) return;

    const url = `${WORD_PRESS_BASE_URL}/json-special`;
    try {
      const response = await fetch(url);
      const contents = (await response.json()) as Record<string, string>[];
      setSpecialJson(contents);
    } catch (error) {
      console.error(error);
    }
  };

  const isRenderWordpressProvider = environment === 'production' || environment === 'staging';

  if (!isRenderWordpressProvider) {
    return <>{children}</>;
  }

  const fetchMagazineJson = async () => {
    if (magazineJson) return;
    const url = `${WORD_PRESS_BASE_URL}/json-magazine`;
    try {
      const response = await fetch(url);
      const contents = (await response.json()) as Record<string, string>[];
      setMagazineJson(contents);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <WordPressContext.Provider
      value={{ newsJson, specialJson, magazineJson, fetchnewsJson, fetchSpecialJson, fetchMagazineJson }}
    >
      {children}
    </WordPressContext.Provider>
  );
};
