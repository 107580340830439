import { createContext, useContext, useState, type FunctionComponent, type ReactNode } from 'react';

type CarListDisplayType = 'oneColumn' | 'twoColumn';
type ContextType = {
  displayType: CarListDisplayType;
  setDisplayType: (displayType: CarListDisplayType) => void;
};

export const CarListDisplayTypeContext = createContext<ContextType>({
  displayType: 'twoColumn',
  setDisplayType: () => {}
});
export const useCarListDisplayTypeContext = (): ContextType => useContext(CarListDisplayTypeContext);

type Props = {
  children: ReactNode;
};

export const CarListDisplayTypeProvider: FunctionComponent<Props> = (props) => {
  const { children } = props;
  const [displayType, setDisplayType] = useState<CarListDisplayType>('twoColumn');

  return (
    <CarListDisplayTypeContext.Provider value={{ displayType, setDisplayType }}>
      {children}
    </CarListDisplayTypeContext.Provider>
  );
};
